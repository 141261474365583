import React from 'react';
import SEO from '../components/seo/SEO';
import ReactPlayer from 'react-player/vimeo'


export default function MabVideo () { 

  const videoStyle = {
    position: 'absolute',
    left: '320px',
    top: '180px',
  };

  return (
    <>
      <SEO title='MAB Video' />
      <ReactPlayer style={ videoStyle } url={ 'https://vimeo.com/306709494' } playing={ true } controls={ true } width='1280px' height='720px' />
    </>
  );

}
